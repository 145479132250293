<template>
  <div class="privacyProtectionAlls">
    <h1>隐私保护</h1>
    <div class="privacyProtection1">
      <div class="privacyProtection2">
        前言
        <p class="privacyProtection3">
          欢迎您访问御之安科技股份有限公司（以下简称“御之安”或“我们”）官方网站！我们深知隐私对您的重要性，并充分尊重您的隐私，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的隐私信息安全可控。基于此，为向您提供本网站相关服务，我们特制定本《隐私保护政策》（以下简称“本政策”）。
        </p>
        <p class="privacyProtection4">
          本政策将向您说明在您使用本网站及相关服务的过程中，我们如何收集、使用及共享、披露您的个人信息，我们如何存储以及采取安全措施保护您的个人信息，以及您对您的个人信息所拥有的管理权利及实现方式。除此之外，我们可能会在收集本政策未说明的相关个人信息前，通过单独的隐私通知或声明的方式向您告知前述内容，并征求您的同意，尤其是在您选择开通或启用一项新的服务时。
          本政策与您所使用的本网站及相关服务息息相关，请务必仔细阅读并了解本政策的全部内容，在确认充分理解并同意全部内容后再使用，尤其是当您通过本网站相关页面主动向我们提供您的个人信息或通过相关操作行为表示同意我们收集您的个人信息时，我们有充分、合理的理由认为您已充分阅读、理解并同意接受本政策的约束。在您自主使用本网站及相关服务时，本政策即在您与我们之间产生法律效力。
          本政策将帮助您了解以下内容： <br />
        </p>
        <div class="privacyProtection5">
          一、本政策的适用范围<br />
          二、我们如何收集及使用个人信息
          <br />三、我们如何共享、转让、公开披露个人信息<br />
          四、信息存储地点和期限 五、我们如何保护个人信息的安全<br />
          六、您如何访问和管理您的个人信息<br />
          七、我们如何使用 Cookie 和同类技术<br />
          八、未成年人保护 <br />九、本政策的更新 <br />十、如何联系我们
        </div>
      </div>
      <div class="privacyProtection6">
        <h2>一、本政策的适用范围</h2>
        <p class="privacyProtection7">
          1.1
          本政策仅适用于御之安官方网站页面，我们可能在官方网站中嵌入我们的关联公司、或所经营业务或产品/服务的专有页面或其他关联平台（如御之安社区、御之安合作伙伴门户平台、御之安安全应急响应中心等）的链接，以便您在有需要时可快捷跳转访问，该等页面均有其专门适用的协议、政策、声明或通过其他形式呈现的条款，您应在访问或使用相关页面时注意查阅。
          <br />1.2本政策不适用于其他第三方通过本网站或其他方式向您提供的产品或服务。当您使用其他第三方向您提供的产品或服务时，您需与该第三方另行就产品或服务使用相关的权利义务协商达成一致。
          <br />1.3
          本协议仅适用于您在使用本网站及相关服务的过程中所涉及的个人信息，包括归属于您本人的个人信息以及归属于其他个人、但经由您获得个人主体的授权后提交的个人信息，针对其他归属于实体单位的信息，我们将按照与对应实体单位签署的合同或协议或通过其他方式达成一致的约定进行处理。
        </p>
      </div>
      <div class="privacyProtection8">
        <h2>二、我们如何收集及使用个人信息</h2>
        <p class="privacyProtection9">
          在您使用本网站及相关服务的过程中，可能涉及您的个人信息的具体情况如下：<br />
          2.1 帮助您成为本网站的注册用户<br />
          您可在本网站的账号注册页面通过提交您的电子邮箱地址及姓名创建账号，成为本网站的注册用户可帮助我们更加高效地为您提供所需的相关服务。<br />
          2.2 为您提供信息反馈渠道，具体包括： <br />2.2.1
          如您需向我们咨询项目方案设计相关问题，您可通过咨询购买页面提交项目相关咨询，您需填写并向我们提供您的姓名、手机号码、电子邮箱地址、所属行业及所在公司名称、职务信息，以便于我们根据您感兴趣的产品或描述的项目信息为您提供符合您需求的反馈。<br />
          2.2.2
          如您有试用御之安产品/服务的需求，您可通过咨询购买页面提交试用申请，您需填写并向我们提供您的姓名、手机号码、电子邮箱地址、所在公司名称及职务信息，以便我们及时响应您的试用需求。<br />
          2.2.3
          您也可直接通过本网站页面直接与我们的在线客服沟通或通过热线电话咨询产品/服务购买或使用相关问题，在线交谈过程中为便于我们与您进一步联系，我们可能需要您提供联系方式等相关个人信息，您可根据自身需求自行决定是否提供。<br />
          2.2.4
          如您希望成为御之安的合作伙伴，您可通过本网站申请合作页面提交您所属公司的相关信息，包括公司名称、联系人姓名、职务、手机号码、电子邮箱地址，以便我们尽快与您联系洽谈友好合作。<br />
          2.2.5
          如您愿意协助御之安建设阳光诚信的业务发展和合作环境，我们鼓励您通过阳光诚信系统就您所知悉的任何与御之安相关的违法违规、有违廉洁或反腐败、反舞弊相关规定的行为或相关线索向我们进行举报，举报时您可选择是否提供您个人的姓名及手机号码或电子邮箱地址，如您提供了您的相关个人信息，我们承诺将仅会用于与您联系核实您举报的相关情况或向您提供相应的奖励，不会用于其他任何用途；您还可通过阳光诚信系统进行自纠自查申报，确认申报时您需填写并提交您所属公司名称、姓名及联系电话。<br />
          2.3
          为维护本网站的安全稳定运行以及不断优化改进您的使用体验，在您使用本网站的过程中，我们可能会通过程序代码自动收集您访问本网站时所用设备信息，以及您和您的设备如何与本网站交互的信息，包括设备名称、设备型号、IMEI
          号码、手机型号、Mac地址、序列号、IP
          地址、操作系统版本等设备信息，您使用本网站的时间和持续时长、网站的事件日志（如重启、升级、错误、崩溃等）等信息。<br />
          2.4
          您使用御之安品牌产品或其他相关服务所涉及的个人信息将通过具体产品或服务各自适用的隐私保护政策及用户协议/服务协议向您进行列举说明，并将仅在您使用具体产品或服务时才会按照相关协议约定进行处理。如本网站或其他具体产品或服务提供需要您提交本政策或其专门适用的协议中所述范围以外的其他相关个人信息，我们会通过更新协议或弹窗通知或线下签署授权函等书面方式另行征得您的同意。<br />
          2.5
          您充分知晓，依据可适用的法律，在以下情形中，我们收集、使用相关个人信息无需征得您的授权同意：<br />
          2.5.1 与我们履行法律法规规定的义务相关的；<br />
          2.5.2 与国家安全、国防安全直接相关的；<br />
          2.5.3 与公共安全、公共卫生、重大公共利益直接相关的； <br />2.5.4
          与刑事侦查、起诉、审判和判决执行等直接相关的；<br />
          2.5.5
          出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br />
          2.5.6 所涉及的个人信息是您已自行向社会公众公开的；<br />
          2.5.7 为签订和履行您或企业用户与我们之间达成的合同/协议所必需的；
          <br />2.5.8
          从合法公开披露的信息，如合法的新闻报道、政府信息公开等渠道，收集相关个人信息的；<br />
          2.5.9
          维护我们提供的产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。
        </p>
      </div>
      <div class="privacyProtection10">
        <h2>三、我们如何共享、转让、公开披露个人信息</h2>
        <div class="privacyProtection11">
          3.1 共享<br />
          我们不会向第三方共享您的个人信息，但以下情形除外：<br />
          3.1.1
          在获取您明确同意情况下的共享。在向您告知第三方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，且获得您的明确同意后，我们会向您同意的第三方共享您授权范围内的信息。<br />
          3.1.2
          在法定情形下的共享。根据适用的法律法规、法律程序、诉讼/仲裁、政府的强制命令、监管要求而需要共享您的个人信息；如为了满足相关法律法规的网络实名认证要求，向相关的政府机关或其指定、认可的第三方共享您的个人信息。<br />
          3.1.3
          在法律要求或允许的范围内，为了保护您或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方。<br />
          3.1.4
          为了保护国家安全、公共安全以及您和其他主体的重大合法权益而需要共享您的个人信息。<br />
          3.1.5 您自行公开的或者我们能从其他合法公开渠道获取到您的个人信息。<br />
          3.1.6
          我们可能会将您的个人信息向我们的关联方披露，以供它们为您提供交易支持、服务支持或安全支持。例如，为避免用户重复注册账号，我们需要对拟注册的账号进行唯一性校验；我们仅会出于特定、明确且合法的目的向我们的关联方共享您的信息，并且只会共享提供服务所必需的信息。<br />
          3.1.7
          为提供产品/服务之必要，共享给业务合作伙伴。我们可能会向合作伙伴等第三方共享您的姓名及联系方式、账号信息、订单信息、设备信息，以保障顺利为您提供所需的产品/服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且会控制在最小必要范围内，我们保证不滥用您的个人信息。我们与合作伙伴共享信息的具体情形包括：<br />
          3.1.7.1
          由于御之安数年来一直坚定推行渠道化战略，致力于与经销商合作伙伴共同为广大客户提供优质、更契合使用需求的产品及服务，因此如您基于本政策第2.2.1条、第2.2.2条、第2.2.3条以及第2.2.4条所述场景而通过本网站页面向我们提交您的相关个人信息，即表示您同意我们将您的个人信息用于为您提供所需的御之安品牌产品/服务或其他相关资讯信息的目的，并同意我们通过经销商合作伙伴与您联络或借助第三方平台向您推送宣传或营销相关信息。<br />
          3.1.7.2
          为向您提供产品或服务之必要，我们可能会将您的姓名、联系方式、所在地址等个人信息提供给相关服务提供商或机构，如物流配送服务提供商、支付结算及开票相关服务机构等。<br />
          我们在引入合作伙伴之前会进行信息安全相关调查或确认，并通过合同约束要求合作伙伴采取严格的措施来保护您的个人信息。如有新增本政策所述提供产品或服务必要范围以外的其他合作伙伴，我们会在向该合作伙伴共享您的信息前另行征得您的同意。<br />
          3.2 转让<br />
          我们不会将处理/受托处理的个人信息转让给任何公司、组织和个人，但以下情况除外：<br />
          3.2.1 已征得您的明确同意。<br />
          3.2.2
          在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您的个人信息的公司、组织继续受此隐私保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。如果不涉及个人信息转让，我们会对您进行充分告知，并将我们处理的个人信息进行删除或匿名化处理。<br />
          3.3 公开披露 我们不会将相关个人信息进行公开披露，但以下情况除外：<br />
          3.3.1
          基于已征得您的明确同意或您的主动选择，我们可能会公开披露相关个人信息。<br />
          3.3.2
          基于法律规定/要求的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露相关个人信息。<br />
          3.3.3
          出于维护公共利益的目的，披露是合理且必须的，则我们可披露相关个人信息。<br />
          3.4
          在以下情形中，我们共享、转让、公开披露相关个人信息无需事先征得您的授权同意：<br />
          3.4.1 基于与国家安全、国防安全相关的原因；<br />
          3.4.2 基于与公共安全、公共卫生、重大公共利益相关的原因；<br />
          3.4.3 基于与犯罪侦查、起诉、审判、和判决执行相关的原因；<br />
          3.4.4
          出于维护用户或其他个人的生命、财产等重大合法权益但又很难征得您的同意的；<br />
          3.4.5
          披露从合法公开的信息中收集的数据信息的，如合法的新闻报道、政府信息公开等渠道；<br />
          3.4.6
          出于维护所提供产品或服务的安全稳定运行所必需，如发现、处置产品或服务的漏洞或故障问题。
        </div>
      </div>
      <div class="privacyProtection12">
        <h2>四、信息存储地点和期限</h2>
        <div class="privacyProtection13">
          4.1我们在中华人民共和国境内处理/受托处理的数据信息将存储于中华人民共和国境内的服务器上，并将按照相关法律规定或与用户约定的期限（需满足为用户提供产品或服务的必要需求）存储。<br />
          4.2
          若为了处理跨境业务，确需向境外机构传输境内处理的相关数据信息的，我们会另行向您告知并征得您的同意，并按照法律、行政法规和相关监管机构的规定执行。我们会确保相关个人信息得到足够的保护，例如对个人信息进行匿名化处理、采取安全加密措施存储和传输等。<br />
          4.3
          您了解并同意，为了安全及备份的需要，我们可能将所处理的数据信息储存到关联公司的服务器上。<br />
          4.4
          我们通常仅在为您提供服务期间保留您的信息，保存期限不会超过满足相关使用目的所必需的期限，但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的全部或部分信息：<br />
          4.4.1 遵守适用的法律法规等有关规定。<br />
          4.4.2 遵守法院判决、裁定或其他法律程序的要求。<br />
          4.4.3 遵守相关行政、司法机关或其他有权机关的要求。<br />
          4.4.4
          为执行本政策或其他相关协议的约定、或处理投诉/纠纷、或保护您或其他相关主体的人身和财产安全或合法权益所合理必需的。
        </div>
      </div>
      <div class="privacyProtection14">
        <h2>五、我们如何保护个人信息的安全</h2>
        <div class="privacyProtection15">
          5.1
          我们非常重视您的信息安全。我们将努力采取各种技术（包括安全加密、防入侵、防病毒等）和管理方面的安全措施来保护您的个人信息，以防止您的个人信息遭到未经授权访问、使用、公开披露、修改、损坏或丢失。例如，我们会对本网站提供
          HTTPS
          协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；此外，我们会建立用户信息安全管理制度及工作流程，对用户信息的访问进行严格的权限管控，对接触个人信息的工作人员进行权限限制及安全和隐私保护相关培训，并定期进行个人信息安全风险评估，及时处置相关风险问题，以便不断提升个人信息保护的安全能力。<br />
          5.2
          尽管我们有责任保护您的个人信息，但您也有责任合理使用并妥善保管您的账号相关信息，因此请使用复杂程度高的密码，以便尽可能增强账号的安全性。如果您发现其他人未经您的许可使用您的账号，您应立即向为您分配账号使用权限的人员反馈或联系我们协助您对该未经授权的使用行为进行制止。<br />
          5.3
          如不幸发生信息安全事件（信息泄露、丢失等），我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报信息安全事件的处置情况。<br />
          5.4
          我们会尽力保护您的个人信息，但任何措施都无法做到无懈可击，也没有任何服务或产品、网站、信息传输、计算机系统、网络连接是绝对安全的。如因我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，并导致您的合法权益受损，我们将承担相应的法律责任。但如因您将本网站或所使用的产品/服务账户密码告知他人、或违反本网站/产品/服务使用相关协议的约定、或使用不当/疏于防范等您自身原因、或因黑客攻击/病毒侵入等第三方原因、或不可抗力因素导致发生用户信息泄露/丢失等安全事件，您理解我们将无法承担任何直接或间接的损失或责任。
        </div>
      </div>
      <div class="privacyProtection16">
        <h2>六、您如何访问和管理您的个人信息</h2>
        <div class="privacyProtection17">
          6.1
          您有权访问和管理您的个人信息。与此同时，您理解并同意：为保障您的账号安全及其他相关合法权益，不同的个人信息访问或管理请求可能需满足不同的前提条件（例如变更账号密码等基本信息需要进行身份验证）；且基于前述我们对收集及使用相关个人信息的目的的说明，您对个人信息的管理可能与您正常使用本网站或其他产品/服务的需求产生冲突。因您自主行使对数据信息的管理权而导致产品或服务的使用障碍或遭受的损失，我们将无法承担责任。<br />
          6.2 在满足一定条件的情况下，本网站可为您提供如下管理个人信息的途径：
          6.2.1 在您注册完成后，您可对账号信息进行修改或完善等。<br />
          6.2.2
          您可注销本网站账号。当我们协助您注销账号后，我们将按照相关法律法规的要求保留您的相关信息；超出法定保存期限后，我们将依法删除或匿名化处理您的个人信息。
          6.3 在以下情况下，您也可以向我们提出删除相关个人信息的要求：<br />
          6.3.1 我们处理/受托处理相关个人信息的行为违反相关法律法规。<br />
          6.3.2 我们主动收集、使用您的个人信息，却未征得您的明确同意。<br />
          6.3.3 我们处理/受托处理相关个人信息的行为严重违反相关协议约定。<br />
          6.4 响应您行使管理权的请求 <br />6.4.1
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于无端重复、或需要过多技术投入（例如需开发新系统或从根本上改变现行惯例）、或可能给他人合法权益带来风险或者非常不切实际（例如涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br />
          6.4.2
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />
          6.4.3 在以下情形中，我们将无法响应您的请求：<br />
          6.4.3.1 与您履行法律法规规定的义务相关的；<br />
          6.4.3.2 与国家安全、国防安全直接相关的；<br />
          6.4.3.3 与公共安全、公共卫生、重大公共利益直接相关的；<br />
          6.4.3.4 与刑事侦查、起诉、审判和执行判决等直接相关的；<br />
          6.4.3.5 有充分证据表明您存在主观恶意或滥用权利的；<br />
          6.4.3.6 出于维护个人生命、财产等重大合法权益而无法响应的；<br />
          6.4.3.7
          响应您的请求将导致相关个人、组织的其他合法权益受到严重损害的；<br />
          6.4.3.8 涉及商业秘密的。
        </div>
      </div>
      <div class="privacyProtection18">
        <h2>七、我们如何使用Cookie和同类技术</h2>
        <div class="privacyProtection19">
          7.1
          Cookie是访问网站时放置在您的计算机或移动设备上的小型数据文件。Cookie
          的内容只能由创建它的服务器检索或读取。我们可能会通过Cookie记录和使用您的信息，但通过Cookie记录的信息仅在您所使用的设备本地保存，以及仅在您使用本网站相关功能/服务时使用。我们使用Cookie的具体用途包括：<br />
          7.1.1
          记住您的身份，以便于您登录和完成验证，例如：Cookie有助于我们辨认您作为我们的注册用户的身份。<br />
          7.1.2 用于存储您的操作设置或偏好，以便您可获得更高效的使用体验。<br />
          7.1.3统计并分析您使用我们网站的情况，以便为您提供更加周到的个性化服务，可能包括但不限定制化页面、内容推荐等。<br />
          7.2 其他类似的技术
          除Cookie之外，我们可能会使用其他同类技术来自动收集信息。例如，我们可能会使用浏览器网络存储（包括通过HTML5），也称为本地存储对象，从而达到与Cookie类似的目的。<br />
          7.3大部分网络浏览器都有设置阻止Cookie和清除浏览器网络存储的功能，您可根据自己的偏好进行管理或清除。但请注意，如果您设置阻止Cookie或其他同类技术的运行，您可能无法享受最佳的服务体验，某些功能的可用性也可能会受到影响。
        </div>
      </div>
      <div class="privacyProtection20">
        <h2>七、我们如何使用Cookie和同类技术</h2>
        <div class="privacyProtection21">
          7.1
          Cookie是访问网站时放置在您的计算机或移动设备上的小型数据文件。Cookie
          的内容只能由创建它的服务器检索或读取。我们可能会通过Cookie记录和使用您的信息，但通过Cookie记录的信息仅在您所使用的设备本地保存，以及仅在您使用本网站相关功能/服务时使用。我们使用Cookie的具体用途包括：<br />
          7.1.1
          记住您的身份，以便于您登录和完成验证，例如：Cookie有助于我们辨认您作为我们的注册用户的身份。<br />
          7.1.2 用于存储您的操作设置或偏好，以便您可获得更高效的使用体验。<br />
          7.1.3统计并分析您使用我们网站的情况，以便为您提供更加周到的个性化服务，可能包括但不限定制化页面、内容推荐等。<br />
          7.2 其他类似的技术<br />
          除Cookie之外，我们可能会使用其他同类技术来自动收集信息。例如，我们可能会使用浏览器网络存储（包括通过HTML5），也称为本地存储对象，从而达到与Cookie类似的目的。<br />
          7.3大部分网络浏览器都有设置阻止Cookie和清除浏览器网络存储的功能，您可根据自己的偏好进行管理或清除。但请注意，如果您设置阻止Cookie或其他同类技术的运行，您可能无法享受最佳的服务体验，某些功能的可用性也可能会受到影响。
        </div>
      </div>
      <div class="privacyProtection22">
        <h2>八、未成年人保护</h2>
        <div class="privacyProtection23">
          8.1
          我们主要面向成年人提供本网站服务及其他相关产品。我们非常重视对未成年人个人信息的保护，如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策的所有内容，并在征得您的父母或其他监护人同意的前提下使用我们的产品或服务以及向我们提供信息。<br />
          8.2对于收集经父母或其他监护人同意使用本网站的未成年人个人信息的情况，我们只会在法律规定允许、父母或其他监护人明确同意或者保护未成年人所必要的情况下处理此信息。<br />
          8.3
          如果御之安发现自己在未事先获得可证实的父母或其他监护人同意的情况下，收集了未成年人的个人信息，则会设法尽快获取有效的授权同意或删除相关个人信息。
        </div>
      </div>
      <div class="privacyProtection24">
        <h2>九、本政策的更新</h2>
        <div class="privacyProtection25">
          我们可能会根据我们经营的业务或对本网站的管理或处理个人信息的具体情况等变化而不时修订更新本政策。如本政策的更新可能造成您在本政策下享有的权利的实质性减少，或涉及扩大我们收集并处理个人信息的范围等重要规则的变更，我们将通过在本网站页面公示或进行弹窗、或向您发送电子邮件或其他方式通知您查看更新后的政策内容。在前述情况下，若您继续使用我们的网站，即表示您已同意接受更新后的本政策内容。
        </div>
      </div>
      <div class="privacyProtection26">
        <h2>十、如何联系我们</h2>
        <div class="privacyProtection27">
          如您对本政策或服务使用相关其他事宜存有疑问，您可通过拨打客服热线15680975517
          或发送电子邮件至15680975517@126.com与我们联系反馈，您也可根据本网站页面提供的指引提交相关意见或建议，我们将在接收到您提交的信息后尽快审核，并在明确您的需求后及时予以反馈。
        </div>
      </div>
      <div class="privacyProtection28">成都御之安</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.privacyProtectionAlls {
  width: 100%;
  padding: 25px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  h1 {
    text-align: center;
  }
  .privacyProtection1 {
    margin: 0 auto;
    padding: 20px;
    .privacyProtection3 {
      padding-top: 20px;
      text-indent: 2rem;
      padding: 10px 0;
      font-size: 20px;
    }
    .privacyProtection4 {
      padding-top: 20px;
      text-indent: 2rem;
      padding: 10px 0;
      font-size: 20px;
    }
    .privacyProtection5 {
      padding-top: 20px;
      padding: 10px 0;
      font-size: 20px;
      font-weight: 800;
    }
    .privacyProtection6 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection7 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection8 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection9 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection10 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection11 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection12 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection13 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection14 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection15 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection16 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection17 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection18 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection19 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection20 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection21 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection22 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection23 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection24 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection25 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection26 {
      h2 {
        background-color: #eff2f7;
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
        padding: 10px 30px;
      }
      .privacyProtection27 {
        padding: 10px 0;
        font-size: 20px;
        
      }
    }
    .privacyProtection28 {
      text-align: right;
      font-size: 24px;
      margin-top: 40px;
    }
  }
}
</style>